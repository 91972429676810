<template>
    <div>
        <topHeader></topHeader>
        <main>
            <router-view></router-view>
        </main>
        <bottomFooter></bottomFooter>
        <Modal :modalShow="walletShown" @modalClosed="walletShown = false">
            <walletConnect></walletConnect>
        </Modal>
    </div>
</template>
<script>
export default {
    data() {
        return {
            walletConnet: false, walletShown: false,
        };
    },
    mounted() {
        var self=this;
        window.addEventListener('trigerwalletPopupOpen', async(e) => {
            self.walletShown=true;
        },false);

        window.addEventListener('dfinityWalletConnected', async(e) => {
            self.walletShown=false;
        },false);
    }
}
</script>