import header from '@/components/TopHeader'
import bottomFooter from '@/components/bottomFooter'
import LoaderT1 from '@/components/loaderT1.vue'
import PlayerBox from '@/components/Player.vue'
import Modal from '@/components/modal.vue'
import walletConnect from '@/components/walletConnect.vue'

export default {
    register(app){
        app.component('topHeader', header);
        app.component('bottomFooter', bottomFooter);
        app.component('LoaderT1', LoaderT1);
        app.component('PlayerBox', PlayerBox);
        app.component('Modal', Modal);
        app.component('walletConnect', walletConnect)
    }
 }
