<template>
    <div class="modal" v-if="modalStat">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" :class="(noheader?'md-hdr-free':'')">
                <div class="modal-header"><button v-if="close" type="button" @click.prevent="toggleClose()" class="close">X</button></div>
                <div class="modal-body" :class="minPadding?'m-p-min':''"><slot /></div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name:'Modal',
    data () {
        return {
            modalStat:false,
        }
    },
    created() {
        this.modalStat = this.modalShow;
    },
    mounted(){
      
    },
    props: {
        modalShow: { type:Boolean , default:false },
        close:{type:Boolean , default:true},
        keyBoardCloase:{ type:Boolean , default:true},
        noheader:{ type:Boolean , default:false},
        minPadding:{ type:Boolean , default:false}
    },
    methods: {
        toggleClose:function(){ 
            this.$emit('modalClosed');this.modalStat=false;
            document.querySelector('body').classList.remove('modal-active')
        },
        keyDownHandler(e) {
            if(e.key === "Escape") {
                this.toggleClose()
            }
        // Your handler code here
        },
    },
    watch:{
        modalShow:function(){ 
            this.modalStat = (this.modalShow)?true:false 
            if(this.modalStat){
                document.querySelector('body').classList.add('modal-active')
            }else{
                document.querySelector('body').classList.remove('modal-active')
            }
            if(this.modalStat && this.keyBoardCloase){
                window.addEventListener('keydown', this.keyDownHandler)
            }else{
                window.removeEventListener('keydown', this.keyDownHandler)
            }
        }
    },
}
</script>