import { createApp } from 'vue'
import App from './App.vue'
import helpers from "./plugins/helpers";
import router from './router'
import components from './components'
import artemis from "artemis-web3-adapter";

var connectObj = { whitelist: ['nrq3s-cyaaa-aaaah-aby5a-cai', 'zlja6-aqaaa-aaaah-abxfq-cai', 'ryjl3-tyaaa-aaaaa-aaaba-cai'], host: 'https://boundary.ic0.app/'}
const app = createApp(App);
app.config.globalProperties.helpers = helpers;
app.config.globalProperties.connectObj = connectObj;
app.config.globalProperties.artemis = new artemis(connectObj);

components.register(app)
app.use(router);
app.mount("#app");
