<template>
  <div class="wallet-connect-wrapper">
    <div class="wallet-connect-content">
      <h2>Sign In</h2>
      <h4>Choose one of available <span>wallet</span> providers or create a new wallet.</h4>
    </div>
    <div class="wallet-select-section mt-1" :class="inprogress ? 'w-connect' : ''">
      <div class="in-progress" v-if="inprogress">
        <div class="d-flex"><span class="spinner"></span></div>
      </div>
      <div class="wallets" :class="inprogress ? 'sc-lock' : ''">
        <div class="wallet" v-for="(item) in walletsList" :key="item.id">
          <div class="wallet-provider-wrapper" v-if="item.id!='dfinity'"  @click.prevent="connectWallet(item.id, item.chain)">
            <div class="wallet-provider d-flex">
              <div class="icon brder-s1">
                <span :style="'background: url(' + item.icon + ');'"></span>
              </div>
              <div class="name ">
                <p>{{ item.name }}</p>
                <span> {{ item.adapter.readyState }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    chain: { type: String, default: 'all' }
  },
  data() {
    return {
      walletsList: [], inprogress: false, chainSelected: 'all'
    }
  },
  created() {

  },
  async beforeMount() {

  },
  async mounted() {
    this.walletsList = this.artemis.wallets
  },
  methods: {
    connectWallet: async function (wallet, chain) {
      this.inprogress = true
      var s = await this.artemis.connect(wallet);
      this.inprogress = false
    },
    changeChain: async function (chainid) {
      this.chainSelector(chainid)
    }
  }
};
</script>
