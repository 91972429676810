import { createRouter, createWebHistory } from 'vue-router';
import BaseLayout from '@/Layout/BaseLayout'

const routes = [
    {
      path: '/', name: 'Default', beforeEnter: (to, from, next)=>{ next(); } , component: BaseLayout,
      children: [
        { path: '/', name: 'Home', component: () => import('@/views/Home')},
        { path: '/play', name: 'PlayGame', component: () => import('@/views/Play')},
        { path: '/account', name: 'MyAccount', component: () => import('@/views/Account')},
      ] 
    }
]


const router = createRouter({ history: createWebHistory(process.env.BASE_URL), routes , scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }})
  
export default router
  