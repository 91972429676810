<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 txt-cntr">
                    <ul class="ftr-social-icons">
                        <li><a href="https://twitter.com/ic_flip" target="_blank"><i class="ic-twitter"></i> </a></li>
                        <li><a href="https://discord.com/invite/Z9Z8aFKszB" target="_blank"><i class="ic-discord"></i> </a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>